import '../styles/Waves.css'

function Waves(){
    return (
        <section>
            <div className='air air1'></div>
            <div className='air air2'></div>
            <div className='air air3'></div>
            <div className='air air4'></div>
        </section>
    );
}

export default Waves;