import NavBar  from '../components/NavBar';

function Tech (){
    return(
        <div>
            <NavBar/>
            <p> Tech Page!</p>
        </div>
    );
}

export default Tech;